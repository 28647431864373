import React from "react";
import { autobind } from "react-decoration";
import { ApolloProvider, Query } from "react-apollo";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import gql from "graphql-tag";
import ReactHtmlParser from "react-html-parser";

const getExhibitionDetail = gql`
  query getExhibitionDetail($searchForm: ExhibitionDetailForm) {
    getExhibitionDetail(Input: $searchForm) {
        subject
        photo
        onlineDate
        offlineDate
        content
    }
  }
`;

class ExhibitionComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { t, searchForm } = this.props;
    let { exhibitionID } = searchForm;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getExhibitionDetail}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                id: parseInt(exhibitionID),
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <center>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </center>
                );
              if (error) return `Error!${error.message}`;
              if (data && data.getExhibitionDetail) {
                let { getExhibitionDetail } = data;
                let { subject, photo, onlineDate, offlineDate, content } =
                  getExhibitionDetail;
                let photoPath = "/file/downloadFile/exhibition/" + photo;
                if (subject) {
                    return (
                    <section className="section col-12 theme_book">
                        <div className="container">
                        <div className="col">
                            <div className="theme_data">
                            <div className="images">
                                <img alt={subject} src={photoPath} />
                            </div>
                            <h3>{subject}</h3>
                            <ul className="datalist">
                                <li>
                                {ReactHtmlParser(
                                    t("jumperrwd.exhibition.exhibtionTime", {
                                    onlineDate: onlineDate,
                                    offlineDate: offlineDate,
                                    })
                                )}
                                </li>
                            </ul>
                            <div className="explain">
                                {ReactHtmlParser(content)}
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    );
                } else {
                    return "";
                }
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default ExhibitionComp;
